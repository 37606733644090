<template>
  <app-module-view>
    <template slot="body">
      <section class="position-relative">
        <div>
          <div class="top-buttons">
            <div class="d-inline-block">
              <app-checkbox
                id="don_npa_autorefresh"
                v-model="autoRefresh"
                :label="$t('auto_refresh')">
              </app-checkbox>
            </div>
            <div class="d-inline-block">
              <app-button-csv-export
                :data="data.articles"
                filename="Native perf articles"
                :btnClass="btnClass"
              ></app-button-csv-export>
            </div>
          </div>

          <div v-if="autoRefresh">
            <app-autoloader :callback="refreshData" :height="2" :interval="60"></app-autoloader>
          </div>

          <app-filter></app-filter>

          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table id="list-table" class="table table-striped">
                  <thead>
                  <tr>
                    <th>
                      {{ $t("don.npa.status") }}
                    </th>
                    <th>
                      {{ $t("don.npa.agency") }}
                    </th>
                    <th>
                      {{ $t("don.npa.client") }}
                    </th>
                    <th>
                      {{ $t("don.npa.title") }}
                    </th>
                    <th>
                      {{ $t("don.npa.interval") }}
                    </th>
                    <th class="text-right">
                      {{ $t("don.npa.days") }}
                    </th>
                    <th class="text-right">
                      {{ $t("don.npa.impressions") }}
                    </th>
                    <th class="text-right">
                      {{ $t("don.npa.visits") }}
                    </th>
                    <th class="text-right">
                      {{ $t("don.npa.clicks") }}
                    </th>
                    <th class="text-right">
                      {{ $t("don.npa.ctr") }}
                    </th>
                    <th class="text-right">
                      {{ $t("don.npa.clicks_limit") }}
                    </th>
                    <th>
                      {{ $t("don.npa.progress") }}
                    </th>
                    <th>
                      {{ $t("don.npa.bonus") }}
                    </th>
                    <th class="text-right">
                      {{ $t("don.npa.divided_by_days_shortname") }}
                      <app-tooltip :title="$t('don.npa.divided_by_days')"></app-tooltip>
                    </th>
                    <th>
                      {{ $t("don.npa.actions") }}
                    </th>
                  </tr>
                  </thead>
                  <tbody v-if="dataLoaded && data.articles.length > 0">
                  <tr v-for="(item, index) in data.articles" :key="`item-${index}`">
                    <td>
                      <span v-if="item.status == 2" class="label label-success text-uppercase"><strong>{{
                          $t("don.npa.active")
                        }}</strong></span>
                      <span v-else-if="item.status == 3"
                            class="label btn-danger text-uppercase"><strong>{{ $t("don.npa.done") }}</strong></span>
                      <span v-else-if="item.status == 4"
                            class="label btn-green text-uppercase"><strong>{{ $t("don.npa.paused") }}</strong></span>
                      <span v-else class="label btn-primary text-uppercase"><strong>{{
                          $t("don.npa.inactive")
                        }}</strong></span>
                    </td>
                    <td>{{ item.agency }}</td>
                    <td>{{ item.client }}</td>
                    <td>{{ item.title }}</td>
                    <td>{{ item.publishedSince | prettyDate }} - {{ item.publishedUntil | prettyDate }}</td>
                    <td class="text-right">{{ activeDays[index] | formatNumber }}</td>
                    <td class="text-right">{{ item.views }}</td>
                    <td class="text-right">{{ item.nativePerfViews }}</td>
                    <td class="text-right">{{ item.clicks }}</td>
                    <td class="text-right">{{ ctr[index] | formatNumber }}</td>
                    <td class="text-right">{{ item.clicksLimit }}</td>
                    <td>
                      <div class="progress">
                        <div class="progress-bar bg-success"
                             :style="'width: ' + clickProgress[index] + '%; height:20px;'"
                        >
                          {{ clickProgress[index] | formatNumber }} %
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="progress">
                        <div class="progress-bar btn-green"
                             :style="'width: ' + bonusProgress[index] + '%; height:20px;'">
                          {{ bonusProgress[index] | formatNumber }} %
                        </div>
                      </div>
                    </td>
                    <td class="text-right">
                      <i v-if="item.dividedByDays" class="fa fa-check"></i>
                    </td>
                    <td>
                      <router-link
                        tag="button"
                        class="btn btn-default btn-sm"
                        :to="{ name: 'don_npa_detail', params: { id: item.id }, query: { originalPath: '/nativePerfPredictions' } }"
                      >
                        <i class="fa fa-info"></i>
                      </router-link>
                      <router-link
                        tag="button"
                        class="btn btn-default btn-sm"
                        :to="{ name: 'don_npa_edit', params: { id: item.id }, query: { originalPath: '/nativePerfPredictions' } }"
                      >
                        <i class="fa fa-edit"></i>
                      </router-link>
                    </td>
                  </tr>
                  </tbody>
                  <tbody v-if="!dataLoaded">
                  <tr>
                    <td colspan="15" class="loader-td">
                      <app-preloader></app-preloader>
                    </td>
                  </tr>
                  </tbody>
                  <tbody v-if="dataLoaded && data.articles.length < 1">
                  <tr>
                    <td colspan="15">
                      {{ $t("don.npa.total_items_count") }}: {{ data.totalCount }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section v-if="data.totalCount > 0 && dataLoaded" class="position-relative">
        <div>
          <app-predictions-table :data="data.prediction"></app-predictions-table>
        </div>
      </section>
    </template>
  </app-module-view>
</template>
<script>
import ModuleView from '../ModuleView'
import { mapGetters, mapState } from 'vuex'
import Preloader from '../preloader/Preloader'
import DonService from '../../services/don/DonService'
import Tooltip from '../tooltip/Tooltip'
import Filter from './NativePerfPredictionsFilter'
import Autoloader from '../Autoloader'
import Checkbox from '../form/Checkbox'
import ButtonCsvExport from '@/components/shared/ButtonCsvExport'
import PredictionsTable from './NativePerfPredictionsTable'

export default {
  name: 'NativePerfPredictions',
  data () {
    return {
      autoRefresh: false,
      btnClass: 'btn'
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    ...mapGetters({
      data: 'nativePerfPredictions/data',
      dataLoaded: 'nativePerfPredictions/isDataLoaded'
    }),
    activeDays: function () {
      return this.data.articles.map(function (item) {
        return DonService.daysBetween(item.publishedSince, item.publishedUntil)
      })
    },
    ctr: function () {
      return this.data.articles.map(function (item) {
        if (item.clicks > 0 && item.nativePerfViews > 0) {
          return Number.parseFloat(item.clicks / item.nativePerfViews * 100).toFixed(2)
        } else {
          return 0
        }
      })
    },
    clickProgress: function () {
      return this.data.articles.map(function (item) {
        if (item.clicksLimit > 0) {
          const value = ((item.clicks * 100) / item.clicksLimit).toFixed(1)
          return (value > 100) ? 100 : value
        } else {
          return 0
        }
      })
    },
    bonusProgress: function () {
      return this.data.articles.map(function (item) {
        if (item.bonusClicksLimit > 0) {
          const value = ((item.bonusClicks * 100) / item.bonusClicksLimit).toFixed(1)
          return (value > 100) ? 100 : value
        } else {
          return 0
        }
      })
    }
  },
  methods: {
    callDispatch (actionName) {
      this.$store.commit('nativePerfPredictions/setDataLoaded', false)

      this.$store.dispatch(`nativePerfPredictions/${actionName}`)
        .then(() => {
          this.$store.commit('nativePerfPredictions/setDataLoaded', true)
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('nativePerfPredictions/setDataLoaded', true)
        })
    },
    getData () {
      this.callDispatch('fetch')
    },
    refreshData () {
      this.callDispatch('refresh')
    }
  },
  components: {
    appModuleView: ModuleView,
    appPreloader: Preloader,
    appTooltip: Tooltip,
    appFilter: Filter,
    appAutoloader: Autoloader,
    appCheckbox: Checkbox,
    appButtonCsvExport: ButtonCsvExport,
    appPredictionsTable: PredictionsTable
  },
  mounted () {
    this.getData()
  }
}
</script>

<style lang="scss" scoped>
.table-responsive {
  th, td {
    font-size: 14px;

    &:nth-child(2) {
      width: 9%;
    }

    &:nth-child(3) {
      width: 9%;
    }

    &:nth-child(4) {
      width: 17%;
    }
  }
}

.position-relative {
  position: relative;
}

.loader-td {
  position: relative;
  height: 80px;
  background-color: white;
  overflow: hidden;
}

.top-buttons {
  position: absolute;
  top: -60px;
  right: 0;
  z-index: 5;
}

.progress-bar {
  color: #212529;
}

.table-striped tbody tr:nth-of-type(odd) {
  .progress {
    background-color: #fff;
  }
}

#list-table {
  td:last-of-type {
    min-width: 80px;
  }
}

.card-no-border .card {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
